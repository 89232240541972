import React from "react";
import { Row, Col, Card, Typography } from 'antd';
import { TagsOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';


const { Paragraph } = Typography;

export default function HomeShopCard({
  badge= 0,
  ...props}){
  const history = useHistory();

  return (
    <div style={{cursor:'pointer', margin:10}} onClick={()=>history.push("/products")}>
      <Card hoverable>
        <Row>
          <Col span={24} align="middle" justify="center">
            <TagsOutlined style={{fontSize:30, color:'#0A3980'}} />
            <Paragraph style={{fontSize:'20px', color:'#000019'}}>Home Shop</Paragraph>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
