import React, { useState } from "react";
import { Col, Row, Button, Modal, Form, Input, Checkbox } from 'antd';

export default function NewTask(props) {
  const [ title, setTitle ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ directions, setDirections ] = useState("");
  const [ months, setMonths ] = useState([
    {id:1, title:"January", value:false},
    {id:2, title:"February", value:false},
    {id:3, title:"March", value:false},
    {id:4, title:"April", value:false},
    {id:5, title:"May", value:false},
    {id:6, title:"June", value:false},
    {id:7, title:"July", value:false},
    {id:8, title:"August", value:false},
    {id:9, title:"September", value:false},
    {id:10, title:"October", value:false},
    {id:11, title:"November", value:false},
    {id:12, title:"December", value:false}
  ]);

  function onMonthChange(e) {
    let newMonths = months.map(month => {
      if (month.id === e.target.value){
        return {
          ...month,
          value:!month.value
        }
      }else{
        return month
      }
    })
    setMonths(newMonths)
  }

  function handleSubmit(){
    props.onSubmit({months:[...months],title: title, description: description, directions: directions })
    setMonths([
      {id:1, title:"January", value:false},
      {id:2, title:"February", value:false},
      {id:3, title:"March", value:false},
      {id:4, title:"April", value:false},
      {id:5, title:"May", value:false},
      {id:6, title:"June", value:false},
      {id:7, title:"July", value:false},
      {id:8, title:"August", value:false},
      {id:9, title:"September", value:false},
      {id:10, title:"October", value:false},
      {id:11, title:"November", value:false},
      {id:12, title:"December", value:false}
    ]);
    setTitle("");
    setDescription("");
    setDirections("");
  }

  function getMonths(){
    return months.map((month,index)=>(
      <Col span={24} key={month.id}>
        <Checkbox value={month.id} onChange={onMonthChange} checked={month.value}>{month.title}</Checkbox>
      </Col>
    ))
  }
  return (
    <Modal
      title="Create Custom Task"
      visible={props.visible}
      onOk={props.onOk}
      onCancel={props.onCancel}
      footer={[
        <Button key="cancel" onClick={props.onCancel}>
          Cancel
        </Button>,
        <Button disabled={title===""} key="submit" type="primary" onClick={()=>handleSubmit()}>
          Create Task
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
      >
        <Form.Item label="Title">
          <Input id="title" placeholder="Title is Required!" value={title} onChange={(e)=>setTitle(e.target.value)}/>
        </Form.Item>
        <Form.Item label="Description">
          <Input.TextArea value={description} id="description" placeholder="Sample Task Description" onChange={(e)=>setDescription(e.target.value)}/>
        </Form.Item>
        <Form.Item label="Directions">
          <Input.TextArea value={directions} id="directions" placeholder="Sample Task Directions" onChange={(e)=>setDirections(e.target.value)}/>
        </Form.Item>
        <Form.Item label="Months When Task Is Active">
          <Row>
            {getMonths()}
          </Row>
        </Form.Item>
      </Form>
    </Modal>
    );
}
