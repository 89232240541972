import React from "react";
import { List, Col, Row, Typography, Divider, Skeleton } from 'antd';
import "./Settings.css";
import ArticleCard from '../components/ArticleCard';
import { useDataContext, useAppContext } from '../libs/contextLib';
import PageSideColumn from '../components/PageSideColumn';

const { Title } = Typography;

export default function Articles() {
  const { cmsData } = useDataContext();
  const { isAuthenticated } = useAppContext();
  const articles = cmsData.articleLibrary;

    return (
      <Row gutter={10} style={{margin:30}} >
        <Col
          xs={{span:24, order:0}}
          sm={{span:24, order:0}}
          md={{span:20, order:0, offset:2}}
          lg={{span:20,order:0, offset:2}}
        >
          <Row>
            <Col span={24}>
              <Title level={4}>Home Guides</Title>
              <Divider />
            </Col>
          </Row>
          <Row gutter={15}>
            {isAuthenticated?(
              <Col>
                <List
                  grid={{
                    gutter: 10,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 3,
                  }}
                  locale={{emptyText: (<Skeleton active />)}}
                  style={{marginBotton:15}}
                  dataSource={articles}
                  renderItem={article=>(
                    <List.Item>
                      <ArticleCard
                        image={article.image}
                        title={article.title}
                        description={article.description}
                        url={'/articles/'+article.id}
                      />
                    </List.Item>
                  )}
                />
              </Col>
            ):(
              <>
                <Col
                  xs={{span:24, order:1}}
                  sm={{span:24, order:1}}
                  md={{span:24, order:1}}
                  lg={{span:18, order:0}}
                >
                  <List
                    grid={{
                      gutter: 10,
                      xs: 1,
                      sm: 2,
                      md: 2,
                      lg: 2,
                      xl: 3,
                      xxl: 3,
                    }}
                    locale={{emptyText: (<Skeleton active />)}}
                    style={{marginBotton:15}}
                    dataSource={articles}
                    renderItem={article=>(
                      <List.Item>
                        <ArticleCard
                          image={article.image}
                          title={article.title}
                          description={article.description}
                          url={'/articles/'+article.id}
                        />
                      </List.Item>
                    )}
                  />
                </Col>
                <PageSideColumn />
              </>
            )}
          </Row>
        </Col>
      </Row>
    );
}
