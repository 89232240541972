import React, { useState } from "react";
import { Col, Row, Typography, Button, Divider, Select, message, Descriptions } from 'antd';
import { useHomeContext, useDataContext } from '../libs/contextLib';
import TaskList from '../components/TaskList';
import { useHistory } from 'react-router-dom';
import { updateHome } from '../libs/databaseLib';
import TaskProgressCard from '../components/TaskProgressCard'
import { track } from '../libs/analyticsLib';
import { PlusOutlined } from '@ant-design/icons';
import NewTask from '../components/NewTask';
import { v4 as uuidv4 } from 'uuid';


const { Title, Text } = Typography;
const { Option } = Select;
const key = 'updatable';

const openMessage = () => {
  message.success({ content: 'Nice work!', key, duration: 2 });
};

const openNewTaskMessage = () => {
  message.success({ content: 'New task saved!', key, duration: 2 });
};


export default function MaintenanceList() {
  const { home, setHome } = useHomeContext();
  const { cmsData } = useDataContext();
  const date = new Date();
  const month = date.toLocaleString('default', { month: 'long' });
  const history = useHistory();
  const currentMonth = new Date().getMonth() +1;
  const [ viewSelection, setViewSelection ] = useState("month");
  const [ selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [ newTaskModal, setNewTaskModal ] = useState(false);
  const [ tasks, setTasks ] = useState( (home.homeDetails && home.homeDetails.activeTasks) ? (home.homeDetails.activeTasks.tasks):( cmsData.taskLibrary.filter(task=> (task.months[currentMonth]))) );

  function getTasksForMonth(month){
    setSelectedMonth(month);
    if(month === 13){
      setTasks(home.homeDetails.tasks)
    }else if(month === currentMonth){
      setTasks(home.homeDetails.activeTasks.tasks)
    }else{
      setTasks(home.homeDetails.tasks.filter(task=> (task.months[month])) )
    }
  }

  function toggleCompleted(completedId){
    let task = home.homeDetails.activeTasks.tasks.filter(checkTask => checkTask.activeTaskId === completedId)[0];
    const wasCompleted = task.completed;
    if(task.completed === false){
      openMessage()
    };
    // edit active tasks
    let newActiveTasks = home.homeDetails.activeTasks.tasks.map(checkTask => {
      if (checkTask.activeTaskId === completedId){
        return({
          ...checkTask,
          completed: !checkTask.completed
        })
      }else{
        return checkTask
      }
    })

    // edit home tasks (history of completions)
    let newHomeTasks = home.homeDetails.tasks.map(checkTask => {
      if (checkTask.homeTaskId === task.homeTaskId){
        if(wasCompleted){
          checkTask.history = checkTask.history.slice(0,checkTask.history.length - 1)
        }else{
          checkTask.history.push(new Date())
        }
      }
      return checkTask
    })

    let newHome = {
      homeId: home.homeId,
      homeDetails: {
        ...home.homeDetails,
        tasks:newHomeTasks,
        activeTasks:{
          ...home.homeDetails.activeTasks,
          tasks:newActiveTasks,
        }
      }
    }
    track({
      event:"Task Completed",
      properties:task
    });

    setHome(newHome);
    updateHome(newHome);
    setTasks(newActiveTasks);
  }

  function handleChange(value) {
    setViewSelection(value);
  }

  let overdueTasks = tasks.filter(task=>(task.completed === false && task.overdue === true))
  var normalDueTasks = [];

  if(selectedMonth === currentMonth){
    normalDueTasks = tasks.filter(task=> (task.completed === false && task.overdue === false))
  }else{
    normalDueTasks = tasks
  }
  let completedTasks = tasks.filter(task=>task.completed === true)


  function getMonthSelector(){
    return (

      <Row>
        <Col>
          <Text level={2} style={{fontSize:"16px", color:'#000000', marginRight:10}}>Preview maintenance for other months</Text>
          <Select style={{marginTop:10, marginBotton:10, width:150}} defaultValue={selectedMonth} onChange={getTasksForMonth}>
            <Option value={1}>January</Option>
            <Option value={2}>February</Option>
            <Option value={3}>March</Option>
            <Option value={4}>April</Option>
            <Option value={5}>May</Option>
            <Option value={6}>June</Option>
            <Option value={7}>July</Option>
            <Option value={8}>August</Option>
            <Option value={9}>September</Option>
            <Option value={10}>October</Option>
            <Option value={11}>November</Option>
            <Option value={12}>December</Option>
            <Option value={13}>All Tasks</Option>
          </Select>
        </Col>
      </Row>

    )
  }

  function taskListToShow(){
    switch(viewSelection){
      case "month":
        return(
          <>
            {(home.homeDetails && home.homeDetails.activeTasks && home.homeDetails.activeTasks.tasks)?(
              <>
                <TaskList
                  toggleCompleted={toggleCompleted}
                  title={"To Do:"}
                  month={selectedMonth}
                  tasks={overdueTasks.concat(normalDueTasks)}
                  setTasks={setTasks}
                  style={{marginBottom:15}}
                  demo={!(selectedMonth === currentMonth)}
                />
                <br />
                {(completedTasks.length > 0)?(
                  <TaskList
                    toggleCompleted={toggleCompleted}
                    title={"Completed:"}
                    month={selectedMonth}
                    tasks={completedTasks}
                    setTasks={setTasks}
                    demo={!(selectedMonth === currentMonth)}
                  />
                ):(<></>)}
              </>
            ):(
              <TaskList
                toggleCompleted={toggleCompleted}
                title={"Sample Monthly To Do List"}
                month={selectedMonth}
                tasks={tasks}
                setTasks={setTasks}
                demo={true}
              />
            )}
          </>
        )
      case "home":
        return(
          <TaskList
            title={"Your Home's Annual Maintenance Tasks"}
            tasks={home.homeDetails.tasks}
            demo={true}
          />
        )
      case "library":
        return(
          <TaskList
            title={"Full Task Library"}
            tasks={cmsData.taskLibrary}
            demo={true}
          />
        )
      default:
        return(
          <TaskList
            title={"Full Task Library"}
            tasks={cmsData.taskLibrary}
            demo={true}
          />
        )
    }

  }

  function handleOk() {
    setNewTaskModal(false);
  };

  function createMonthsObject(months){
    let newMonthsObject = {};
    months.forEach(month=>newMonthsObject[month.id] = month.value)
    return newMonthsObject;
  }

  function createTaskObject(task){
    // custom tasks get dbId of "000"
    let newId = "custom-"+uuidv4();
    let dbId = "custom-"+uuidv4();
    let newTaskObject = {
      // id that ties it back to the database of tasks
      dbId: dbId,
      id: dbId,
      image:null,
      video:null,
      data:{},
      image_alt:null,
      frequency:"",
      cost:"",
      time:"",
      learnMoreURL:"",
      taskId:newId,
      title: task.title,
      description:task.description,
      steps:task.directions,
      homeTaskId: newId,
      note:"",
      months:createMonthsObject(task.months),
      history:[],
      tags:[],
      products:[]
    }
    return newTaskObject;
  }

  function handleSubmit(newTaskDetails){
    setNewTaskModal(false);
    let newTask = createTaskObject(newTaskDetails);
    let newHomeTasks = home.homeDetails.tasks.concat(newTask);
    let newActiveTasks = [...home.homeDetails.activeTasks.tasks];
    if(newTask.months[new Date().getMonth() +1]){
      newActiveTasks.push({
        ...newTask,
        activeTaskId: 'custom-'+uuidv4(),
        overdue: false,
        completed: false,
      });
    }
    let newHome = {
      homeId: home.homeId,
      homeDetails: {
        ...home.homeDetails,
        tasks:newHomeTasks,
        activeTasks:{
          ...home.homeDetails.activeTasks,
          tasks:newActiveTasks,
        }
      }
    }
    setHome(newHome);
    updateHome(newHome);
    setTasks(newActiveTasks);
    track({
      event:"Task Created",
      properties:newTask
    });
    openNewTaskMessage();
  }

  if(false){
    return(getMonthSelector())
  }

  return (
    <Row gutter={10} style={{margin:30}} >
      <Col
        xs={{span:24, order:0}}
        sm={{span:24, order:0}}
        md={{span:20, order:0, offset:2}}
        lg={{span:20,order:0, offset:2}}
      >
        <NewTask
          visible={newTaskModal}
          onOk={handleOk}
          onCancel={handleOk}
          onSubmit={handleSubmit}
        />
        <Row>
          <Col span={24}>
            {(home.homeDetails && home.homeDetails.activeTasks && home.homeDetails.activeTasks.tasks)?(
              <>
                <Title level={4}>Maintenance List</Title>
                <Text style={{marginRight:5}}>Showing: </Text>
                <Select defaultValue="month" style={{width:'100%', maxWidth:350}} onChange={handleChange}>
                  <Option value="month">Maintenance For {month}</Option>
                  <Option value="home">Annual Maintenance For My Home</Option>
                  <Option value="library">All Maintenance Tutorials</Option>
                </Select>
              </>
            ):(
              <>
                <Title level={3} style={{color:"#0A3980"}}>To get started, customize your maintenance plan by answering a few questions for us. For now, we're showing you a sample of what your plan will look like.</Title>
                <Button size="large" type="primary" onClick={()=>history.push("/create-home")} style={{alignSelf:'center'}}><Text strong style={{color:"#FFF"}}>Make My Custom Plan</Text></Button>
              </>
            )}
            <Divider />
          </Col>
        </Row>
        <Row gutter={15} >
          <Col
            xs={{span:24, order:0}}
            sm={{span:24, order:0}}
            md={{span:16, order:0}}
            lg={{span:16, order:0}}
            xl={{span:16, order:0}}
          >
            <Button onClick={()=>setNewTaskModal(true)}>
              <PlusOutlined /> Task
            </Button>
            {taskListToShow()}
          </Col>
          <Col
            xs={{span:24, order:1}}
            sm={{span:24, order:1}}
            md={{span:8, order:1}}
            lg={{span:8, order:1}}
            xl={{span:8, order:1}}

          >
            <TaskProgressCard  completed={completedTasks.length} total={normalDueTasks.length + overdueTasks.length} />
            <Descriptions column={1} bordered style={{marginTop:15}}>
              <Descriptions.Item label="Remaining Tasks This Month">{normalDueTasks.length + overdueTasks.length}</Descriptions.Item>
              <Descriptions.Item label="Completed Tasks This Month">{completedTasks.length}</Descriptions.Item>
              <Descriptions.Item label="Overdue Tasks This Month">{overdueTasks.length}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
