import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Typography, Card, Button } from 'antd';

const { Text } = Typography;

export default function SignupCard() {
  const history = useHistory();

  return (
    <Card hoverable style={{width:'100%', backgroundColor:'#DEEEFC', marginBottom:15}}>
      <Row type="flex" justify="center" align="middle">
        <Col span={24} style={{textAlign:'center'}}>
          <Text style={{size:'20px', color:'#000'}}>Save money and master your home with a free account.</Text>
          <Button block style={{marginTop:25, backgroundColor:"#79DE76", borderColor:"#79DE76", color:"#000"}} onClick={()=>history.push('/signup')}>Create Account</Button>
        </Col>
      </Row>
    </Card>
  );
}
