import React from "react";
import { List, Col, Row, Typography, Divider } from 'antd';
import "./Settings.css";
import ProductCard from '../components/ProductCard';
import { useDataContext } from '../libs/contextLib';

const { Title, Text } = Typography;

export default function Products() {
  const { cmsData } = useDataContext();
  const products = cmsData.productLibrary;

  return (
    <Row gutter={10} style={{margin:30}} >
      <Col
        xs={{span:24, order:0}}
        sm={{span:24, order:0}}
        md={{span:20, order:0, offset:2}}
        lg={{span:20,order:0, offset:2}}
      >
        <Row>
          <Col span={24}>
            <Title level={4}>Recommended Home Products</Title>
            <Text style={{fontSize:12}}>Disclosure: We are an Amazon Associate and some of the links below are affiliate links, meaning, at no additional cost to you, we can earn a commission if you click through and make a purchase.</Text>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col>
            <List
              grid={{
                gutter: 10,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 4,
                xl: 4,
                xxl: 4,
              }}
              locale={{emptyText: "Loading your shop..."}}
              dataSource={products}
              renderItem={product=>(
                <List.Item>
                <ProductCard
                  image_url={product.image}
                  image_alt={product.image_alt}
                  title={product.title}
                  description={product.description}
                  url={product.url}
                />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
