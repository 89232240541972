import React from "react";
import { useAppContext } from "../libs/contextLib";
import { Col, Card, Row, Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import backgroundImage from '../assets/home_backsplash.jpg';
import HomeDashboard from './HomeDashboard';

const { Title, Paragraph } = Typography;

export default function Home() {
  const { isAuthenticated } = useAppContext();

  function renderLander(){
    return(
      <div style={{
        minHeight:'85vh',
        flex:1,
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment:'fixed'
      }}>
      <Row >
      <Col
        flex="auto"
        style={{height:'100%'}}
        xs={{span:22, offset:1}}
        sm={{span:22, offset:1}}
        md={{span:16, offset:4}}
        lg={{span:16, offset:4}}
        xl={{span:16, offset:4}}
        align="middle"
        justify="center"
        >
        <Card style={{marginTop:30, width:'100%', backgroundColor:'rgba(255, 255, 255, 0.95)', borderRadius:'5px'}}>
        <Title level={1}>HomeStow</Title>
        <Title level={3}>Welcome Home!</Title>
        <Paragraph style={{width:'50%'}}>You're only a few steps away from having a maintenance plan that is customized for your home. Create your account to get started.</Paragraph>
          <Row justify="center">
            <Button shape="round" size="large">
              <Link to="/login">
                Login
              </Link>
            </Button>
            </Row>
            <Row justify="center">
            <Button style={{marginTop:10}} type="primary" shape="round" size="large">
              <Link style={{color:'#FFF'}} to="/signup">
                Signup
              </Link>
            </Button>
            </Row>
          </Card>
        </Col>
      </Row>
      </div>
    )
  }

  return (
    <div>
      {isAuthenticated ? <HomeDashboard /> : renderLander()}
    </div>
  );
}
