import React from "react";
import { Row, Col, Card, Typography, Statistic, Progress } from 'antd';
import { useHistory } from 'react-router-dom';
import {useHomeContext} from '../libs/contextLib';


const { Text, Title } = Typography;

export default function TaskProgressCard(){
  const { home } = useHomeContext();
  const history = useHistory();
  let total = home.homeDetails.activeTasks.tasks.length
  let completed = home.homeDetails.activeTasks.tasks.filter(task=>task.completed === true).length

  return (
    <div style={{cursor:'pointer'}} onClick={()=>history.push("/maintenance")}>
    <Card hoverable style={{borderColor:'#0A3980', borderWidth:'2px'}}>
      <Row>
        <Col span={24} align="middle" justify="center">
          <Title level={4}>Tasks Completed</Title>
          <Progress
            type="dashboard"
            strokeColor={{
              '0%':'#87d068',
              '100%':'#108ee9',
            }}
            percent={(completed/total)*100}
            format={() => {
              return(<Statistic
                value={completed}
                valueStyle={(completed<(total/2))?{color:"#CF1322", fontSize:"40px"}:{color:"#79DE76", fontSize:"40px"}}
                suffix={<Text style={{color:"#000019", fontSize:"40px"}}>/ {total}</Text>}
              />)
            }}
          />
        </Col>
      </Row>
    </Card>
    </div>
  );
}
