import { API } from "aws-amplify";
import { onError } from "./errorLib";


export async function searchCMS(searchString) {
    try{
      let result = await API.get("dataTable",searchString);
      return(result);
    } catch(e) {
      onError(e);
      return([])
    }
}

// FUNCTIONS TO GET ALL ARTICLES AND SINGLE ARTICLE
export async function getAllArticles() {
    try{
      let result = await API.get("dataTable","/cms/articles?_sort=id:DESC");
      return(result);
    } catch(e) {
      onError(e);
      return([]);
    }
}

export async function getArticleById(id) {
    try{
      const searchString = "/cms/articles/"+id;
      let result = await API.get("dataTable",searchString);
      return(result);
    } catch(e) {
      onError(e);
      return([])
    }
}

export async function getArticleBySearchString(search) {
    try{
      const searchString = "/cms/articles"+search;
      let result = await API.get("dataTable",searchString);
      return(result);
    } catch(e) {
      onError(e);
      return([])
    }
}

// FUNCTIONS TO GET ALL PRODUCTS
export async function getAllProducts() {
    try{
      let result = await API.get("dataTable","/cms/products");
      return(result);
    } catch(e) {
      onError(e);
      return([])
    }
}

// FUNCTIONS TO GET ALL TASKS AND SINGLE TASK

export async function getAllTasks() {
    try{
      let result = await API.get("dataTable","/cms/tasks");
      return(result);
    } catch(e) {
      onError(e);
      return([])
    }
}

export async function getTaskById(id) {
    try{
      let result = await API.get("dataTable","/cms/tasks/"+id);
      return(result);
    } catch(e) {
      onError(e);
      return([])
    }
}
