import React from "react";
import { Badge, Row, Col, Card, Typography } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';


const { Paragraph } = Typography;

export default function ArticleCard({
  badge= 0,
  ...props}){
  const history = useHistory();

  return (
    <div style={{cursor:'pointer', margin:10}} onClick={()=>history.push("/articles")}>
      <Badge count={badge}>
        <Card hoverable>
          <Row>
            <Col span={24} align="middle" justify="center">
              <BookOutlined style={{fontSize:30, color:'#0A3980'}} />
              <Paragraph style={{fontSize:'20px', color:'#000019'}}>Home Guides</Paragraph>
            </Col>
          </Row>
        </Card>
      </Badge>
    </div>
  );
}
