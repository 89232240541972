import React from "react";
import { useHistory } from "react-router-dom";
import { Typography, Card, message, Button } from 'antd';
import TaskList from "../components/TaskList";
import { useHomeContext, useAppContext } from "../libs/contextLib";
import { updateHome } from '../libs/databaseLib';
import { track } from '../libs/analyticsLib';

const { Text } = Typography;
const key = 'updatable';

const openMessage = () => {
  message.success({ content: 'Nice work!', key, duration: 2 });
};

export default function UpNextCard({number= 2,...props}){
  const history = useHistory();
  const { isAuthenticated } = useAppContext();
  const { home, setHome } = useHomeContext();
  let totalTasks = [];

  if(isAuthenticated){
    totalTasks = home.homeDetails.activeTasks.tasks.filter(task=>task.completed === false);
  }

  function toggleCompleted(completedId){
    let task = home.homeDetails.activeTasks.tasks.filter(checkTask => checkTask.activeTaskId === completedId);
    const wasCompleted = task.completed;
    if(!wasCompleted){openMessage()};
    // edit active tasks
    let newActiveTasks = home.homeDetails.activeTasks.tasks.map(checkTask => {
      if (checkTask.activeTaskId === completedId){
        return({
          ...checkTask,
          completed: !checkTask.completed
        })
      }else{
        return checkTask
      }
    })

    // edit home tasks (history of completions)
    let newHomeTasks = home.homeDetails.tasks.map(checkTask => {
      if (checkTask.homeTaskId === task.homeTaskId){
        if(wasCompleted){
          checkTask.history = checkTask.history.slice(0,checkTask.history.length - 1)
        }else{
          checkTask.history.push(new Date());
          track({
            event:"Task Completed",
            properties:checkTask
          });
        }
      }
      return checkTask
    })

    let newHome = {
      homeId: home.homeId,
      homeDetails: {
        ...home.homeDetails,
        tasks:newHomeTasks,
        activeTasks:{
          ...home.homeDetails.activeTasks,
          tasks:newActiveTasks,
        }
      }
    }
    setHome(newHome);
    updateHome(newHome);
  }

  return (
    <Card
      hoverable
      bordered={false}
      style={{marginTop:15, borderColor:'#E0E0E0'}}
      title={<Text strong style={{color:"#000000", size:'24px'}}>Up Next</Text>}
      extra={<Button type="link" onClick={()=>history.push('/maintenance')}>See All Tasks</Button>}
    >
      <TaskList
        toggleCompleted={toggleCompleted}
        tasks={totalTasks.slice(0,number)}
      />
    </Card>
  );
}
