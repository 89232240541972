// analytics.js

const isLocal = process.env.NODE_ENV === "development";
//
export function identify(props) {
  if (isLocal) {
    return;
  }
  window.analytics.identify(props.id, props.properties);
}

export function track(props) {
  if (isLocal) {
    console.log("tracked");
    console.log(props.event);
    console.log(props.properties);
    return;
  }
  window.analytics.track(props.event, props.properties);
}

export function page(props) {
  if (isLocal) {
    return;
  }else{
    window.analytics.page(props.path);
  }

}
