import React, { useState } from "react";
import { Spin, List, Col, Row, Typography, Divider, Select, Button} from 'antd';
import "./Settings.css";
import ProListingCard from '../components/ProListingCard';
import { useHomeContext } from '../libs/contextLib';
import categories from '../data/categories.json'
import { API } from 'aws-amplify';
import { track } from '../libs/analyticsLib';


const { Title, Text } = Typography;
const {Option} = Select;


export default function HomeProSearch() {
  const [businessList, setBusinessList] = useState([]);
  const { home } = useHomeContext();
  const [ searchTerm, setSearchTerm ] = useState("");
  const [ isLoading, setLoading ] = useState(false);
  const [ resultHeader, setHeader ] = useState("");

  function renderList(){
    if(isLoading){
      return(
        <div
          style={{
            marginTop:'30vh',
            textAlign:'center',
            flex: 1,
            justifyContent: 'center',
            alignItems:'center'
          }}
        >
          <Spin size="large" />
        </div>
      )
    }else if(businessList.length > 0){
      return(
        <List
          grid={{
            gutter: 10,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          header={<Title level={3}>{resultHeader}</Title>}
          style={{width:'100%', marginBotton:15}}
          dataSource={businessList}
          locale={{emptyText: "Unfortunately there are no results in your area for this service."}}
          renderItem={biz=>(
            <List.Item>
              <ProListingCard
                listing={biz}
              />
            </List.Item>
          )}
        />
      )
    }else{
      return(<><Title level={3} style={{marginTop:15}}>{resultHeader}</Title></>)
    }
  }

  function onChange(value) {
    setSearchTerm(value)
  }

  async function handleSearch(){

    setLoading(true);
    API.get('dataTable', '/pros/location/'+home.homeDetails.zip+'/categories/'+searchTerm+'/sort/rating')
    .then((res) => {
      if(res.total === 0){
        setHeader("Unfortunately, there are no results in your area.")
      }else{
        setHeader("There are "+res.total+" results for your area.")
      }
      track({
        event:"Pro Searched",
        properties:{
          zip:home.homeDetails.zip,
          category:searchTerm,
          results:res.total
        }
      });
      setBusinessList(res.businesses);
      setLoading(false);
    })
    .catch((err) =>{
      setLoading(false);
      console.log("ERROR: " + err)
    })
  }

  return (
    <Row gutter={10} style={{margin:30}} >
      <Col
        xs={{span:24, order:0}}
        sm={{span:24, order:0}}
        md={{span:20, order:0, offset:2}}
        lg={{span:20,order:0, offset:2}}
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Title level={4}>Search For Local Home Pros</Title>
              </Col>
            </Row>
            <Row justify="space-between" align="bottom">
              <Col span={20}>
                <Text style={{fontSize:"16px", color:'#000000', marginRight:10}}>Search for </Text>
                <Select
                  showSearch
                  style={{ width: 200, marginRight: 10 }}
                  placeholder="Choose A Category"
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {categories.map(category=><Option key={category.alias} value={category.alias}>{category.title}</Option>)}
                </Select>
                <Button onClick={()=>handleSearch()}>
                  Search
                </Button>
                <br/>
                <Text>Search results are powered by Yelp.</Text>
              </Col>
              <Col span={4}>
                <div style={{height:"20", width:'auto', float:"right"}}>
                  <img alt={"yelp logo"}style={{objectFit:'contain', maxHeight:"100%", maxWidth:"100%"}} src="https://s24.q4cdn.com/143307695/files/design/client-logo.svg"/>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {renderList()}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
