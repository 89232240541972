import React from "react";
import { Col } from 'antd';
import { useAppContext } from "../libs/contextLib";
import TaskProgressCard from '../components/TaskProgressCard';
import UpNextCard from '../components/UpNextCard';
import SignupCard from '../components/SignupCard';
import UnauthenticatedOptionsCard from '../components/UnauthenticatedOptionsCard';

export default function PageSideColumn() {
  const { isAuthenticated } = useAppContext();

  return (
    <>
      {isAuthenticated?(
        <Col
          xs={{span:0}}
          sm={{span:0}}
          md={{span:0}}
          lg={{span:6, order:1}}
        >
          <TaskProgressCard />
          <UpNextCard />
        </Col>
      ):(
        <Col
          xs={{span:24, order:0}}
          sm={{span:24, order:0}}
          md={{span:24, order:0}}
          lg={{span:6, order:1}}
          style={{marginBottom:15}}
        >
          <SignupCard />
          <UnauthenticatedOptionsCard />
        </Col>
      )}
    </>
  );
}
