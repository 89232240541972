import React from "react";
import { Row, Col, Typography, Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import { DoubleRightOutlined } from '@ant-design/icons';


const { Text } = Typography;

export default function TaskCard(props){
  const task = props.task;
  const history = useHistory();

  function handleClick(){
    if (task.activeTaskId) {
      history.push("tasks/active/"+task.activeTaskId);
    } else if (task.dbId) {
      history.push("tasks/home/"+task.dbId.toString());
    } else {
      history.push("tasks/library/"+task.id.toString());
    }
  }

  return (
      <Row align="middle" justify="space-between" style={{width:'100%',cursor:"pointer"}}>
        {props.demo?(
          <></>
        ):(
          <Col span={3}>
            <Checkbox
              checked={task.completed}
              onChange={()=>props.toggleCompleted(task.activeTaskId)}
            />
          </Col>
        )}
        <Col span={props.demo?22:19} onClick={()=>handleClick()}>
          {task.completed?(
            <Text delete style={{fontSize:'16px', color:'#000000', marginLeft:10}} >{task.title}</Text>
          ):(
            <>
              {task.overdue?(
                <Text style={{fontSize:'20px', color:'#9D0000'}} >Overdue: {task.title}</Text>
              ):(
                <Text style={{fontSize:'20px', color:'#000000'}} >{task.title}</Text>
              )}
            </>
          )}
        </Col>
        <Col span={2} onClick={()=>handleClick()}>
          <DoubleRightOutlined  style={{color:"#0A3980"}} />
        </Col>
      </Row>
  );
}
