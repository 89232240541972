import React from "react";
import { Col, Row, Divider } from 'antd';
import "./Settings.css";
import HomeSettings from '../components/HomeSettings';
import LogoutButton from '../components/LogoutButton';

export default function Settings() {
  return (
    <Row type="flex" style={{marginBottom:35}}>
      <Col span={16} offset={4}>
        <HomeSettings />
        <Divider />

        <LogoutButton style={{alignSelf:'cetner'}}/>
      </Col>
    </Row>
  );
}
