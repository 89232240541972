import React, { useState, useEffect } from "react";
import { Col, Row, Menu, Dropdown, Button, Typography, Card, Skeleton, Descriptions, Divider, Checkbox, Modal, message } from 'antd';
import { useParams, useHistory } from "react-router-dom";
import { useDataContext, useHomeContext, useAppContext } from "../libs/contextLib";
import ReactMarkdown from "react-markdown";
import { updateHome } from "../libs/databaseLib";
import ReactPlayer from "react-player"
import ProductCard from "../components/ProductCard";
import PageSideColumn from '../components/PageSideColumn';
import { track } from '../libs/analyticsLib';
import { ThunderboltOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';


// React Markdown Image Styling:
// https://github.com/rexxars/react-markdown/issues/174

const { Title, Text } = Typography;
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December", "January"
];
const monthOrder=[1,7,2,8,3,9,4,10,5,11,6,12];
const key = 'updatable';
const openMessage = () => {
  message.success({ content: 'URL Copied To Clipboard', key, duration: 2 });
};
function Image(props) {
  return(
      <div style={{height:350, width:500, maxWidth:'100%', maxHeight:"100%", alignSelf:'center'}}>
        <img {...props} alt="" width='100%' height='100%'  style={{objectFit:"contain"}}/>
      </div>
  )
}

export default function Task() {
  const { id, status } = useParams();
  const { isAuthenticated } = useAppContext();
  const { cmsData } = useDataContext();
  const { home, setHome } = useHomeContext();
  const [ task, setTask ] = useState({});
  const [ monthsChecked, setMonthsChecked ] = useState([])
  const [ editDisabled, setEditDisabled ] = useState(true);
  const [ rescheduledModal, setRescheduleModal ] = useState(false);
  const [ signupModal, setSignupModal ] = useState(false);
  const [ genericModal, setGenericModal ] = useState(false);
  const [ genericModalMessage, setGenericModalMessage ] = useState("");
  const history = useHistory();
  const customTask = id.includes("custom");

  useEffect(() => {
    getTaskDetails(id);
    // eslint-disable-next-line
  }, [cmsData, home]);

  useEffect(() => {
    trackViewedTask();
    // eslint-disable-next-line
  }, []);

  function trackViewedTask(){
    if ((status==="active"||status==="home") && !isAuthenticated){
      history.push('/tasks')
      return;
    }
    if(status === "active"){
      // get activeTask details
      let activeTask = home.homeDetails.activeTasks.tasks.filter(task => task.activeTaskId === id)[0];
      track({
        event:"Viewed Task",
        properties:{
          status:"active",
          id:activeTask.dbId,
          title:activeTask.title
        }
      });
    }else if (status === "home"){
      // get homeTask
      let homeTask = home.homeDetails.tasks.filter(task => task.dbId.toString() === id)[0];
      track({
        event:"Viewed Task",
        properties:{
          status:"home",
          id:homeTask.dbId,
          title:homeTask.title
        }
      });
    }else{
      // get activeTask details
      track({
        event:"Viewed Task",
        properties:{
          status:"library",
          id: id,
          title:id
        }
      });
    }
  }

  function getTaskDetails(id){
    if ((status==="active"||status==="home") && !isAuthenticated){
      history.push('/tasks')
      return;
    }

    if(status === "active"){
      // get activeTask details
      let activeTask = home.homeDetails.activeTasks.tasks.filter(task => task.activeTaskId === id)[0];
      // get homeTask
      let homeTask = home.homeDetails.tasks.filter(task => task.homeTaskId === activeTask.homeTaskId)[0];
      //get db task Details
      // the completed task is right on active task but not on hometask
      let fullTask = cmsData.taskLibrary.filter(task => task.id === activeTask.dbId)[0];
      let newTask = {
        ...fullTask,
        ...activeTask,
        ...homeTask,
      }
      if (customTask && newTask.description === ""){
        newTask.description = " ";
      }
      newTask.completed = activeTask.completed
      setTask(newTask)
      setMonthArray(homeTask.months);
    }else if (status === "home"){
      // get homeTask
      let homeTask = home.homeDetails.tasks.filter(task => task.dbId.toString() === id)[0];
      //get db task Details
      // the completed task is right on active task but not on hometask
      let fullTask = cmsData.taskLibrary.filter(task => task.id.toString() === id)[0];
      let newTask = {
        ...fullTask,
        ...homeTask,
      }
      if (customTask && newTask.description === ""){
        newTask.description = " ";
      }
      setTask(newTask)
      setMonthArray(homeTask.months);
    }else{
      // get activeTask details
      let task = cmsData.taskLibrary.filter(task => task.id.toString() === id)[0]
      setTask(task)
      if(task){
        setMonthArray(task.months);
      }
    }
  }

  function taskOptionsMenu(){
    if(status === "active"){
      return(
        <Menu>
          {
           /* Logical shortcut for only displaying the
              button if the copy command exists */
           !customTask && document.queryCommandSupported('copy') &&
           <Menu.Item>
             <Button style={{padding:0}} type="text" onClick={()=>copyShareLinkToClipboard()}>
               Share Task
             </Button>
           </Menu.Item>
          }
          <Menu.Item>
            <Button style={{padding:0}} type="text" onClick={()=>handleTaskCompleted()}>
              {task.completed?"Mark Task Incomplete":"Mark Task Completed"}
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button style={{padding:0}} type="text" onClick={()=>ignoreTaskThisMonth()}>
              Ignore Task This Month
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button style={{padding:0}} type="text" onClick={()=>removeTaskFromSchedule()}>
              Remove Task From Schedule
            </Button>
          </Menu.Item>
        </Menu>
      )
    }else if (status === "home"){
      return(
        <Menu>
          {
           /* Logical shortcut for only displaying the
              button if the copy command exists */
           !customTask && document.queryCommandSupported('copy') &&
           <Menu.Item>
             <Button style={{padding:0}} type="text" onClick={()=>copyShareLinkToClipboard()}>
               Share Task
             </Button>
           </Menu.Item>
          }
          <Menu.Item>
            <Button style={{padding:0}} type="text" onClick={()=>removeTaskFromSchedule()}>
              Remove Task From Schedule
            </Button>
          </Menu.Item>
        </Menu>
      )
    }else if (status === "library"){
      if(isAuthenticated){
        return(
          <Menu>
            <Menu.Item>
              <Button style={{padding:0}} type="text" onClick={()=>addTaskToSchedule()}>
                Add Task To Schedule
              </Button>
            </Menu.Item>
          </Menu>
        )
      }else{
        return(
          <Menu>
            <Menu.Item>
              <Button style={{padding:0}} type="text" onClick={()=>setSignupModal(true)}>
                Add Task To Schedule
              </Button>
            </Menu.Item>
          </Menu>
        )
      }
    }else{
      return(<></>)
    }
  }

  function copyShareLinkToClipboard(){
    const el = document.createElement('textarea');
    el.value = "https://app.homestow.com/tasks/library/"+task.id;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    openMessage();
  }

  function ignoreTaskThisMonth(){
    // edit active tasks
    let newActiveTasks = home.homeDetails.activeTasks.tasks.filter(checkTask => checkTask.activeTaskId !== id);
    track({
      event:"Task Ignored",
      properties:task
    });
    let newHome = {
      homeId: home.homeId,
      homeDetails: {
        ...home.homeDetails,
        activeTasks:{
          ...home.homeDetails.activeTasks,
          tasks:newActiveTasks,
        }
      }
    }
    setHome(newHome);
    updateHome(newHome);
    history.push('/home');
  }

  function removeTaskFromSchedule(){

    let newActiveTasks = home.homeDetails.activeTasks.tasks.filter(checkTask => checkTask.activeTaskId !== id);

    // edit home tasks (history of completions)
    let newHomeTasks = home.homeDetails.tasks.filter(checkTask => checkTask.homeTaskId !== task.homeTaskId);
    track({
      event:"Task Removed",
      properties:task
    });

    let newHome = {
      homeId: home.homeId,
      homeDetails: {
        ...home.homeDetails,
        tasks:newHomeTasks,
        activeTasks:{
          ...home.homeDetails.activeTasks,
          tasks:newActiveTasks,
        }
      }
    }
    setHome(newHome);
    updateHome(newHome);
    history.push('/home');
  }

  function addTaskToSchedule(){
    if (home.homeDetails.tasks.filter(checkTask => checkTask.homeTaskId === task.id).length === 0){
      let newHomeTasks = home.homeDetails.tasks.concat(createTaskObject(task));

      let newHome = {
        homeId: home.homeId,
        homeDetails: {
          ...home.homeDetails,
          tasks:newHomeTasks,
        }
      }
      setHome(newHome);
      updateHome(newHome);
    }
    track({
      event:"Task Added",
      properties:task
    });
    handleTaskAction("We added this task to your home maintenance schedule. You'll see this change take effect starting next month!");
  }

  function createTaskObject(task){
    let newTaskObject = {
      // id that ties it back to the database of tasks
      dbId: task.id,
      title: task.title,
      // id that ties it to this user's home specifically
      homeTaskId: uuidv4(),
      note:"",
      months:task.months,
      history:[]
    }
    return newTaskObject;
  }

  function handleOk() {
    setRescheduleModal(false);
  };

  function handleCancel(){
    setSignupModal(false);
  }

  function handleSignup(){
    setSignupModal(false);
    history.push('/signup');
  }

  function handleTaskAction(modalText){
    setGenericModalMessage(modalText);
    setGenericModal(true);
  }


  function setMonthArray(months){
    let returnMonths = [];
    for (let [key, value] of Object.entries(months)) {
      if(value){
        returnMonths.push(key)
      }
    }
    setMonthsChecked(returnMonths);
  }

  function handleTaskCompleted(){
    const wasCompleted = task.completed;
    track({
      event:"Task Completed",
      properties:task
    });
    // edit active tasks
    let newActiveTasks = home.homeDetails.activeTasks.tasks.map(checkTask => {
      if (checkTask.activeTaskId === id){
        return({
          ...checkTask,
          completed: !checkTask.completed
        })
      }else{
        return checkTask
      }
    })

    // edit home tasks (history of completions)
    let newHomeTasks = home.homeDetails.tasks.map(checkTask => {
      if (checkTask.homeTaskId === task.homeTaskId){
        if(wasCompleted){
          checkTask.history = checkTask.history.slice(0,checkTask.history.length - 1)
        }else{
          checkTask.history.push(new Date())
        }
      }
      return checkTask
    })

    let newHome = {
      homeId: home.homeId,
      homeDetails: {
        ...home.homeDetails,
        tasks:newHomeTasks,
        activeTasks:{
          ...home.homeDetails.activeTasks,
          tasks:newActiveTasks,
        }
      }
    }
    setHome(newHome);
    updateHome(newHome);
  }

  function handleUpdatedMonths(){
    let newMonths = {};
    for (let i = 1; i < 13; i++) {
      newMonths[i.toString()] = monthsChecked.includes(i.toString())
    }
    // edit active tasks
    let newActiveTasks = home.homeDetails.activeTasks.tasks.map(checkTask => {

      if(status === "active" || status === "home"){
        if (checkTask.homeTaskId === task.homeTaskId){
          return({
            ...checkTask,
            months: newMonths
          })

        }else{
          return checkTask
        }
      }else{
        if (checkTask.dbId === task.id){
          return({
            ...checkTask,
            months: newMonths
          })

        }else{
          return checkTask
        }
      }
    })
    // edit home tasks (history of completions)
    let newHomeTasks = home.homeDetails.tasks.map(checkTask => {
      if(status === "active" || status === "home"){
        if (checkTask.homeTaskId === task.homeTaskId){
          return({
            ...checkTask,
            months: newMonths
          })

        }else{
          return checkTask
        }
      }else{
        if (checkTask.dbId === task.id){
          return({
            ...checkTask,
            months: newMonths
          })

        }else{
          return checkTask
        }
      }
    })

    let newHome = {
      homeId: home.homeId,
      homeDetails: {
        ...home.homeDetails,
        tasks:newHomeTasks,
        activeTasks:{
          ...home.homeDetails.activeTasks,
          tasks:newActiveTasks,
        }
      }
    }
    setHome(newHome);
    updateHome(newHome);
  }

  function handleEditSchedule(){
    if(isAuthenticated){
      if(!editDisabled){
        setEditDisabled(!editDisabled);
        handleUpdatedMonths();
        setRescheduleModal(true);
      }else{
        setEditDisabled(!editDisabled);
      }
    }else{
      setSignupModal(true);
    }
  }

  function getProducts(){
    if(task && task.products){
      return task.products.map((product, i)=> (
        <Col
          xs={{span:24}}
          sm={{span:24}}
          md={{span:12}}
          lg={{span:12}}
          key={i}
        >
          <ProductCard
            image_url={product.image}
            image_alt={product.image_alt}
            title={product.title}
            description={product.description}
            url={product.url}
          />
        </Col>
      ))
    }else{return(<></>)}
  }

  function getActiveMonths(){
    let monthsToDisplay = [];
    for (let i of monthOrder) {
        monthsToDisplay.push(
          <Col
            span={12}
            key={i}
          >
            <Checkbox
              value={i.toString()}
              checked={task.months.i}
            >
              {monthNames[i-1]}
            </Checkbox>
          </Col>
          );
    }
    return(monthsToDisplay);
  }

  function onMonthChange(checkedValues) {
    setMonthsChecked(checkedValues)
  }

  function taskData(){
    return(
      <>
      {task.description?(
        <>
          {(task.video)?(
            <Row type="flex"
              style={{ alignItems: 'center' }}
              justify="center"
              gutter={16}
            >
              <Col span={24} style={{alignItems:"center", justifyContent:"center"}}>
                <ReactPlayer
                  url={task.video}
                  style={{maxHeight:'100%',maxWidth:"100%"}}
                />
                <Divider />
              </Col>
            </Row>
          ):(
            <>
            {(task.image)?(
              <Row align="middle" justify="center" style={{marginBottom:10}}>
                <Col span={24}>
                  <div style={{height:500, width:'100%', maxWidth:'100%', maxHeight:"100%"}}>
                    <img src={task.image} alt={task.image_alt} width='100%' height='100%' style={{objectFit:"cover"}}/>
                  </div>
                  <Divider />
                </Col>
              </Row>
            ):(
              <></>
            )}
            </>
          )}
          {(task.steps)?(
            <Row style={{marginTop:15}}>
              <Col span={24}>
                <Title level={4}>How To:</Title>
                <ReactMarkdown
                  source={task.steps}
                  renderers={{image: Image}}
                />
                <Divider />
              </Col>
            </Row>
          ):(
            <></>
          )}
          <Row style={{marginTop:10}} type="flex" align="middle" justify="center" gutter={[10,10]}>
            {(task.time || task.cost || task.frequency)?(
              <Col
                xs={{span:24}}
                sm={{span:24}}
                md={{span:12}}
                lg={{span:12}}
              >
                <Card>
                  <Descriptions title="Details" column={1} bordered>
                    <Descriptions.Item label="Time">{task.time}</Descriptions.Item>
                    <Descriptions.Item label="Cost">{task.cost}</Descriptions.Item>
                    <Descriptions.Item label="Frequency">{task.frequency}</Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
            ):(<></>)}
            <Col
              xs={{span:24}}
              sm={{span:24}}
              md={{span:12}}
              lg={{span:12}}
            >
              <Card
                title="Schedule"
                extra={editDisabled?(
                    <>
                      {(status==="library")?(
                        <Button type="link" onClick={()=>handleEditSchedule()}>Customize</Button>
                      ):(
                        <Button type="link" onClick={()=>handleEditSchedule()}>Customize</Button>
                      )}
                    </>
                  ):(
                    <Button type="link" onClick={()=>handleEditSchedule()}>Save</Button>
                  )
                }
              >
                <Text>The months when we'll remind you to do this task:</Text>
                <Checkbox.Group style={{marginTop:10}} disabled={editDisabled} defaultValue={monthsChecked} onChange={onMonthChange}>
                  <Row gutter={[5,2]}>
                    {getActiveMonths()}
                  </Row>
                </Checkbox.Group>
              </Card>
            </Col>
            {getProducts()}
          </Row>
        </>
      ):(
        <Skeleton active />
      )}
      </>
    )
  }

  //loading visualization
  if (!task)return(
    <Skeleton active />
  )
  return (
    <Row type="flex" style={{margin:15, marginTop:35}}>
      <Col
        xs={{span:24}}
        sm={{span:22, offset:1}}
        md={{span:20, offset:2}}
        lg={{span:20, offset:2}}
      >
        <Modal
          visible={genericModal}
          onOk={()=>setGenericModal(false)}
          footer={[
            <Button key="ok" type="primary" onClick={()=>setGenericModal(false)}>
              OK
            </Button>
          ]}
        >
          <p>{genericModalMessage}</p>
        </Modal>
        <Modal
          title="Successfully Rescheduled"
          visible={rescheduledModal}
          onOk={()=>handleOk()}
          footer={[
            <Button key="ok" type="primary" onClick={()=>handleOk()}>
              OK
            </Button>
          ]}
        >
          <p>{'Schedule successfully updated. The changes will take effect at the beginning of '+ monthNames[new Date().getMonth()+1]}</p>
        </Modal>
        <Modal
          title="Account Required"
          visible={signupModal}
          onOk={()=>handleSignup()}
          onCancel={()=>handleCancel()}
          footer={[
            <Button key="cancel" onClick={()=>handleCancel()}>
              Cancel
            </Button>,
            <Button key="signup" type="primary" onClick={()=>handleSignup()}>
              Create Account
            </Button>
          ]}
        >
          <p>You need an account to customize your schedule.</p>
          <p>To get started with your custom maintenance schedule and track your maintenance history you'll need a free account.</p>
        </Modal>
          {task.description?(
            <Row align="center">
              <Col span={24}>
                <Row>
                  {(status === "active")?(
                    <Col span={1}>
                      <Checkbox
                        style={{fontSize:'25px'}}
                        checked={task.completed}
                        onChange={()=>handleTaskCompleted()}
                      />
                    </Col>
                  ):(<></>)}
                  <Col>
                    {task.completed?(
                      <Title delete level={4}>{task.title}</Title>
                    ):(
                      <Title level={4}>{task.title}</Title>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={24} >
                    <Text style={{size:'18px', color:'#000'}}>{task.description}</Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} >
                    <Dropdown overlay={taskOptionsMenu} placement="bottomLeft">
                      <Button style={{padding:0,marginTop:15}} type="text"><ThunderboltOutlined />Task Actions</Button>
                    </Dropdown>
                  </Col>
                </Row>
                <Divider />
              </Col>
            </Row>
          ):(
            <Skeleton active />
          )}
          <Row gutter={15}>
            <Col
              xs={{span:24, order:1}}
              sm={{span:24, order:1}}
              md={{span:24, order:1}}
              lg={{span:18, order:0}}
            >
              {taskData()}
            </Col>
            <PageSideColumn />
          </Row>
        </Col>
      </Row>
    );
}
