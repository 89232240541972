import React, { useState } from 'react';
import { Layout, Menu, Drawer } from 'antd';
import { useAppContext } from "../libs/contextLib";
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import logo from '../assets/logo_white_transparent bg.png';
import {
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import './NavBar.less';

const { Header } = Layout;

export default function NavBar() {
  const { isAuthenticated } = useAppContext();
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const LeftMenu = (props) => (
    <>
      {isAuthenticated
        ?
        <Menu onClick={()=>setVisible(false)} theme={props.theme} mode={props.mode}>
          <Menu.Item key="homeCare">
            <Link to="/maintenance">Home Care</Link>
          </Menu.Item>
          <Menu.Item key="guides">
            <Link to="/articles">Home Guides</Link>
          </Menu.Item>
          <Menu.Item key="pros">
            <Link to="/prosearch">Home Pros</Link>
          </Menu.Item>
          <Menu.Item key="shop">
            <Link to="/products">Home Shop</Link>
          </Menu.Item>
        </Menu>
        :
        <></>
      }
    </>
  )

  const RightMenu = (props) => (
    <>
      {isAuthenticated
        ?
        <Menu onClick={()=>setVisible(false)} theme={props.theme} mode={props.mode}>
          <Menu.Item key="settings">
            <div onClick={()=>history.push("/settings")}>
              <UserOutlined style={{color:'#fff',fontSize:'24px'}}/>
            </div>
          </Menu.Item>
        </Menu>
        :
        <Menu onClick={()=>setVisible(false)} theme={props.theme} mode={props.mode}>
          <Menu.Item key="login">
            <Link to="/login">Login</Link>
          </Menu.Item>
          <Menu.Item key="signup">
            <Link to="/signup">Signup</Link>
          </Menu.Item>
        </Menu>
      }
    </>
  )

  const RightMenuSmall = (props) => (
    <>
      {isAuthenticated
        ?
        <Menu onClick={()=>setVisible(false)} theme="dark" mode={props.mode} style={{}}>
          <Menu.Item key="maintenance">
            <Link to="/maintenance">Home Care</Link>
          </Menu.Item>
          <Menu.Item key="articles">
            <Link to="/articles">Home Guides</Link>
          </Menu.Item>
          <Menu.Item key="prosearch">
            <Link to="/prosearch">Home Pros</Link>
          </Menu.Item>
          <Menu.Item key="products">
            <Link to="/products">Home Shop</Link>
          </Menu.Item>
          <br />
          <Menu.Item key="settings">
            <Link to="/settings">Settings</Link>
          </Menu.Item>
        </Menu>
        :
        <Menu onClick={()=>setVisible(false)} theme="dark" mode={props.mode}>
          <Menu.Item key="login">
            <Link to="/login">Login</Link>
          </Menu.Item>
          <Menu.Item key="signup">
            <Link to="/signup">Signup</Link>
          </Menu.Item>
        </Menu>
      }
    </>
  )

  return (
      <Header className="menu" style={{position:'fixed', zIndex: 1, width: '100%'}}>
        {isAuthenticated?(
          <Link to="/home"><img className="menu__logo" src={logo} alt="HomeStow logo"/></Link>
        ):(
          <a href="https://www.homestow.com" alt="homestow website" ><img className="menu__logo" src={logo} alt="HomeStow logo"/></a>
        )}
        <div className="menu__container">
          <div className="menu_left">
            <LeftMenu mode="horizontal" theme="dark"/>
          </div>
          <div className="menu_rigth">
            <RightMenu mode="horizontal" theme="dark"/>
          </div>
          <div
            className="menu__mobile-button"
            onClick={()=>setVisible(true)}
          >
            <MenuOutlined  style={{color:"#fff"}}/>
          </div>
          <Drawer
            drawerStyle={{backgroundColor:'#0A3980'}}
            title="Menu"
            placement="right"
            className="menu_drawer"
            closable={false}
            onClose={()=>setVisible(false)}
            visible={visible}
          >
            <RightMenuSmall mode="inline" theme="light"/>
          </Drawer>
        </div>
      </Header>
  );
}
