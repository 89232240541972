import { API } from "aws-amplify";
import { onError } from "./errorLib";
import { identify, track } from "./analyticsLib";

// ######## create user ########
// input for function is an object with the following properties:
//    userId - uid of user to be created
//    userDetails - object with email address and homeId; homeId is uid created before home is actually made
// user = {
//  userId: #string
//  userDetails: {
//    email: #string
//    homeId: #string
//  }
//}
export async function createUser(user){
  let apiRequest = {
    body: user
  }
  try {
    // try to get results
    let result = await API.post('dataTable', '/user',apiRequest);
    identify({
      id: user.userId,
      properties: user.userDetails
    })
    track({
      event:"Account Created",
      properties: user
    });
    // if successful, return Result!
    return result;
  }  catch (e) {
    onError(e)
  }
}

// read user
export async function getUser(userId){
  let response = await API.get('dataTable', '/user/'+userId);
  identify({
    id:response.pk.split('#')[1],
    properties:response.userDetails
  });
  return response;
}

// update user
//API.put("dataTable", "/user/"+user.userId, apiRequest);



// create home
export async function createHome(home){
  let apiRequest = {
    body: home
  }
  try {
    // try to get results
    let result = await API.post('dataTable', '/home',apiRequest);
    track({
      event:"Home Created",
      properties: home
    });
    // if successful, return Result!
    return result;
  }  catch (e) {
    onError(e)
    return 0;
  }
}

// read home
export async function getHome(homeId){
  let result = await API.get('dataTable', '/home/'+homeId)
  return result;
}

// update home
export async function updateHome(home){
  try{
      API.put('dataTable', '/home/'+home.homeId,{
      body:{
        homeDetails:home.homeDetails
      }
    });
    return true;
  } catch (e) {
    onError(e);
    return false;
  }
}


// full app load call
export async function loadDataFromDb(token) {
  try {
    // get user details
    let response = await getUser(token.idToken.payload.sub);
    // get home details
    let home = {}
    // if home has been created...
    if (("homeId" in response.userDetails)&&response.userDetails.homeId !=="" ){
      home = await getHome(response.userDetails.homeId);
    // if home has not bee created
    }else{
      home = {
        homeId:"",
        homeDetails:{
          zip: "",
          address1:"",
          address2:"",
          city:"",
          state:"",
          ac: "",
          homeType: "",
          allergies: "",
          pets:"",
          homeOptions:[
            {
              label:'Home Exterior',
              title:"exterior",
              checked:false
            },{
              label:'In-ground Irrigation',
              title:"irrigation",
              checked:false
            },{
              label:'Chimney',
              title:"chimney",
              checked:false
            },{
              label:'In-sink Garbage Disposal',
              title:"garbageDisposal",
              checked:false
            },{
              label:'Septic Tank',
              title:"septic",
              checked:false
            },{
              label:'Roof',
              title:"roof",
              checked:false
            },{
              label:'Attic',
              title:"attic",
              checked:false
            },{
              label:'Basement',
              title:"basement",
              checked:false
            },{
              label:'Garage',
              title:"garage",
              checked:false
            },{
              label:'Driveway',
              title:"driveway",
              checked:false
            },{
              label:'Washer and Dryer',
              title:"laundry",
              checked:false
            },{
              label:'Water Filtration',
              title:"waterFilter",
              checked:false
            },{
              label:'Water Softener',
              title:"waterSoftener",
              checked:false
            },{
              label:'HVAC System',
              title:"hvac",
              checked:false
            }
          ],
          waterHeater:"",
          savingMoney:0,
          retainValue:0,
          healthyEnvironment:0,
          reducingEnergy:0,
          preventBreakdown:0
        }
      }
    }
    return {
      user: {
        userId:response.pk.split('#')[1],
        userDetails:response.userDetails
      },
      home: {
        homeId: response.userDetails.homeId,
        homeDetails: home.homeDetails
      }
    }
  } catch(e) {
    if (e !== 'No current user') {
      onError(e);
    }
    return 0;
  }
}
