import React from "react";
import zero_stars from "../assets/stars/regular_0@2x.png"
import one_stars from "../assets/stars/regular_1@2x.png"
import two_stars from "../assets/stars/regular_2@2x.png"
import three_stars from "../assets/stars/regular_3@2x.png"
import four_stars from "../assets/stars/regular_4@2x.png"
import five_stars from "../assets/stars/regular_5@2x.png"
import one_half_stars from "../assets/stars/regular_1_half@2x.png"
import two_half_stars from "../assets/stars/regular_2_half@2x.png"
import three_half_stars from "../assets/stars/regular_3_half@2x.png"
import four_half_stars from "../assets/stars/regular_4_half@2x.png"


export default function YelpStars({stars}){
  switch(stars){
    case 0:
      return(<img style={{objectFit:'contain', maxWidth:"100%", maxHeight:"100%"}} alt={"zero stars"} src={zero_stars} />)
    case 1:
      return(<img style={{objectFit:'contain', maxWidth:"100%", maxHeight:"100%"}} alt={"one star"} src={one_stars} />)
    case 1.5:
      return(<img style={{objectFit:'contain', maxWidth:"100%", maxHeight:"100%"}} alt={"one and a half stars"} src={one_half_stars} />)
    case 2:
      return(<img style={{objectFit:'contain', maxWidth:"100%", maxHeight:"100%"}} alt={"two stars"} src={two_stars} />)
    case 2.5:
      return(<img style={{objectFit:'contain', maxWidth:"100%", maxHeight:"100%"}} alt={"two and a half stars"} src={two_half_stars} />)
    case 3:
      return(<img style={{objectFit:'contain', maxWidth:"100%", maxHeight:"100%"}} alt={"three stars"} src={three_stars} />)
    case 3.5:
      return(<img style={{objectFit:'contain', maxWidth:"100%", maxHeight:"100%"}} alt={"three and a half stars"} src={three_half_stars} />)
    case 4:
      return(<img style={{objectFit:'contain', maxWidth:"100%", maxHeight:"100%"}} alt={"four stars"} src={four_stars} />)
    case 4.5:
      return(<img style={{objectFit:'contain', maxWidth:"100%", maxHeight:"100%"}} alt={"four and a half stars"} src={four_half_stars} />)
    case 5:
      return(<img style={{objectFit:'contain', maxWidth:"100%", maxHeight:"100%"}} alt={"five stars"} src={five_stars} />)
    default:
      return(<></>)
  }
}
