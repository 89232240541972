import React, { useState } from "react";
import { Col, Card, Row, Typography, Button, List, Skeleton } from 'antd';
import { useHomeContext, useDataContext } from '../libs/contextLib';
import { useHistory } from 'react-router-dom';
import MonthlyCareCard from '../components/MonthlyCareCard';
import HomeShopCard from '../components/HomeShopCard';
import ProSearchCard from '../components/ProSearchCard';
import GuideCard from '../components/GuideCard';
import ArticleCard from '../components/ArticleCard';
import TaskProgressCard from '../components/TaskProgressCard';
import UpNextCard from '../components/UpNextCard';

const { Text, Title } = Typography;

export default function HomeDashboard() {
  const { home } = useHomeContext();
  const { cmsData } = useDataContext();
  const articles = cmsData.articleLibrary.filter(article => ((article.id === 5) || (article.id === 1)));
  const history = useHistory();
  const currentMonth = new Date().getMonth() +1;
  const [ tasks ] = useState( (home.homeDetails && home.homeDetails.activeTasks) ? (home.homeDetails.activeTasks.tasks):( cmsData.taskLibrary.filter(task=> (task.months[currentMonth]))) );

  let normalDueTasks = tasks.filter(task=>task.completed === false)

  function subMenu(){
    if(true){return(<></>)}
    return(
      <Row>
        <Col
          xs={{span:0, order:0}}
          sm={{span:0, order:0}}
          md={{span:24, order:0}}
          lg={{span:24,order:0}}
        >
          <Card style={{backgroundColor: '#1C3868' }}>
            <Row justify="space-around" type="flex" gutter={[10,10]} style={{padding:10}}>
              <Col style={{height:'100%'}} order={0}>
                <MonthlyCareCard badge={normalDueTasks.length}/>
              </Col>
              <Col style={{height:'100%'}} order={0}>
                <GuideCard />
              </Col>
              <Col style={{height:'100%'}}order={1}>
                <ProSearchCard />
              </Col>
              <Col style={{height:'100%'}} order={1}>
                <HomeShopCard badge={3}/>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }

  return (
    <Row gutter={[30, 16]} style={{margin:30}} >
      <Col
        xs={{span:24, order:0}}
        sm={{span:24, order:0}}
        md={{span:24, order:0, offset:0}}
        lg={{span:22, order:0, offset:1}}
        xl={{span:20, order:0, offset:2}}
      >
      {subMenu()}
      <Row style={{marginTop:10}}   gutter={[30, 15]}>
        <Col
          xs={{span:24, order:0}}
          sm={{span:24, order:0}}
          md={{span:14, order:0}}
          lg={{span:14,order:0}}
        >
        {(home.homeId==="")?(
          <Card style={{marginTop:15}}>
            <Row direction="vertical" justify="center" align="middle">
              <Col span={24} align="middle" justify="center">
                <Title level={4} style={{color:"#0A3980"}}>To get started, please answer a few questions for us so we customize your maintenance plan.</Title>
                <Button size="large" type="primary" onClick={()=>history.push("/create-home")}>Make My Custom Plan</Button>
              </Col>
            </Row>
          </Card>
        ):(
          <>
            <TaskProgressCard />
            <UpNextCard number={20}/>
          </>
        )}
        </Col>
        <Col
          xs={{span:24, order:1}}
          sm={{span:24, order:1}}
          md={{span:10, order:1}}
          lg={{span:10, order:1}}
        >
          <Card
            bordered={false}
            style={{borderColor:'#E0E0E0'}}
            title={<Text strong style={{color:"#000000", size:'24px'}}>Must Reads For Homeowners</Text>}
            extra={<Button type="link" onClick={()=>history.push('/articles')}>See All</Button>}
          >
            <Row >
              <Col>
                <List
                  grid={{
                    gutter: 10,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                  }}
                  locale={{emptyText: (<Skeleton active />)}}
                  dataSource={articles}
                  renderItem={article=>(
                    <List.Item>
                      <ArticleCard
                        image={article.image}
                        title={article.title}
                        description={article.description}
                        url={'/articles/'+article.id}
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      </Col>
    </Row>
  );
}
