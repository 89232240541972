import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Card, Button, Typography } from 'antd';
const {Text} = Typography;


export default function UnauthenticatedOptionsCard() {
  const history = useHistory();

  return (
    <Card style={{width:'100%'}}>
      <Row type="flex" justify="center" align="middle">
        <Col span={24} style={{textAlign:'center'}}>
          <Text style={{size:'18px', color:'#000', marginBottom:15}}>Preview free homeowner resources without an account.</Text>
          <Button type="link" onClick={()=>history.push('/tasks')}>Home Maintenance Library</Button>
          <Button type="link" onClick={()=>history.push('/articles')}>Home Guides</Button>
        </Col>
      </Row>
    </Card>
  );
}
