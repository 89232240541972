import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Col, Row, Typography, Card } from 'antd';
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Signup.css";
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { createUser } from '../libs/databaseLib';

const { Title, Paragraph, Text } = Typography;

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmationCode: "",
    phoneNumber:"",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { userHasAuthenticated } = useAppContext();

  function validateForm() {
    return (
      fields.email.length > 0 &&
      validatePasswordRequirements(fields.password)
    );
  }

  function validatePasswordRequirements(pw) {
    let length = pw.length > 7;
    let lowercase = (pw.toUpperCase() !== pw);
    let uppercase = (pw.toLowerCase() !== pw);
    let number = /\d/g.test(pw);
    return(length && lowercase && uppercase && number)
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length === 6;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      if(e.message === "User is already confirmed."){
        alert(
          'Looks like your account exists. We will redirect you to the login page.'
        );
        history.push("/login");
        setIsLoading(false);
      }else if(e.name === "UsernameExistsException"){
        alert(
          'An account for this email address has already been registered. To confirm the account we will email you a 6-digit code. Please check your email for the the code we just sent then enter it in the field below. ' +
          'If you have previously received a confirmation code, make sure you get the most recent one that we just sent you.',
        );
        try{
          Auth.resendSignUp(fields.email)
          setNewUser({
            username: fields.email,
            password: fields.password,
          });
          setIsLoading(false);
        }catch(error){
          if(error.message === "User is already confirmed."){
            alert(
              'Looks like you already have an account. We will redirect you to the login page.'
            );
            history.push("/login");
            setIsLoading(false);
          }else{
            onError(error);
            setIsLoading(false);
          }
        }
      } else {
        onError(e);
        setIsLoading(false);
      }
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      let user = await Auth.signIn(fields.email, fields.password);
      let dataBaseUser = {
        userId: user.username,
        userDetails: {
          email: fields.email,
          phoneNumber: fields.phoneNumber,
          homeId: ""
        }
      };
      await createUser(dataBaseUser);
      userHasAuthenticated(true);
      setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <Form
        layout="vertical"
        style={{padding:'60px'}}
        onSubmit={handleConfirmationSubmit}
      >
        <Paragraph>We just emailed you a 6-digit confirmation code. Please check your email for this confirmation code and enter it below to set up your account.</Paragraph>
        <Form.Item
          name='confirmationCode'
          rules={[{required: true, message: "Please check your email for the confirmation code and enter it to set up your account."}]}
        >
          <Input
            placeholder="6-digit confirmation code"
            type='confirmationCode'
            value={fields.confirmationCode}
            onChange={handleFieldChange}
          />
        </Form.Item>
        <Row align="middle" justify="center">
          <Col span={24}>
            <LoaderButton
              shape="round"
              type="primary"
              isLoading={isLoading}
              disabled={!validateConfirmationForm()}
              onClick={handleConfirmationSubmit}
            >
              <Text strong style={{color:validateConfirmationForm()?"#FFF":"#D9D9D9"}}>Verify</Text>
            </LoaderButton>
          </Col>
        </Row>
      </Form>
    );
  }

  function renderForm() {
    return (
      <>
        <Form
          onSubmit={handleSubmit}
          layout="vertical"
          title="Create your username and password"
        >
          <Form.Item
            name='phoneNumber'
            label="Phone Number"
            type="tel"
          >
            <Input
              type="tel"
              id='phoneNumber'
              placeholder="XXX-XXX-XXXX"
              value={fields.phoneNumber}
              onChange={handleFieldChange}
            />
          </Form.Item>
          <Form.Item
            name='email'
            label="Email Address"
            rules={[{required: true, message: "Please enter your email address"}]}
          >
            <Input
              type='email'
              placeholder="yourname@example.com"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </Form.Item>
          <Form.Item
            name='password'
            label="Password"
            rules={[{required: true, message: "Please create your password"}]}
            extra="Password must be at least 8 characters and contain a number, a capital letter, and a lowercase letter"
          >
            <Input.Password
              type='password'
              placeholder="Go0d Passw0rd1"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </Form.Item>
        </Form>
        <Row type="flex" justify="center" align="middle">
          <Col span={24} type="flex" justify="center" align="middle" >
            <LoaderButton
              type="primary"
              style={{width:'100%', marginBottom:15}}
              isLoading={isLoading}
              disabled={!validateForm()}
              onClick={handleSubmit}
            >
              <Text strong style={{color:validateForm()?"#FFF":"#D9D9D9"}}>Submit</Text>
            </LoaderButton>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle" >
          <Col  type="flex" justify="center" align="middle" >
            <Paragraph>Already have an account?</Paragraph>
            <Link to="/login">Login</Link>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <div style={{
      minHeight:'85vh',
      flex:1,
      backgroundImage: `url(https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80)`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}>
    <Row>
      <Col
        xs={{span:22, offset:1}}
        sm={{span:22, offset:1}}
        md={{span:18, offset:3}}
        lg={{span:16, offset:4}}
      >
        <Card style={{marginTop:15, width:'100%', backgroundColor:'rgba(255, 255, 255, 0.95)', borderRadius:'5px'}}>
          <Row justify="center" align="middle" style={{marginTop:15, marginBottom:15}} >
            <Col style={{textAlign:'center'}}>
              <Title level={3}>Sign Up For HomeStow</Title>
              <Text>Get Your:</Text><br />
              <Text>Custom Maintenance Plan, Home Maintenance Tracking,</Text><br />
              <Text>Recommended Articles, and Top Reviewed Home Products</Text>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{span:22, offset:1}}
              sm={{span:22, offset:1}}
              md={{span:18, offset:3}}
              lg={{span:16, offset:4}}
            >
              {newUser === null ? renderForm() : renderConfirmationForm()}
            </Col>
          </Row>

        </Card>
      </Col>
    </Row>
    </div>
  );
}
