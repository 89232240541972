import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Settings from "./containers/Settings";
import MaintenanceList from "./containers/MaintenanceList";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Articles from "./containers/Articles";
import Article from "./containers/Article";
import Task from "./containers/Task";
import TaskLibrary from "./containers/TaskLibrary";
import Products from "./containers/Products";
import HomeOnboarding from "./containers/HomeOnboarding";
import HomeProSearch from "./containers/HomeProSearch";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/home">
        <Home />
      </Route>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route exact path="/articles">
        <Articles />
      </Route>
      <Route exact path="/products">
        <Products />
      </Route>
      <Route exact path="/tasks/:status/:id">
        <Task />
      </Route>
      <Route exact path="/tasks">
        <TaskLibrary />
      </Route>
      <Route exact path="/articles/:id">
        <Article />
      </Route>
      <AuthenticatedRoute exact path="/prosearch">
        <HomeProSearch />
      </AuthenticatedRoute >
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/create-home">
        <HomeOnboarding />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/maintenance">
        <MaintenanceList />
      </AuthenticatedRoute >
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
