import React from "react";
import { Card } from 'antd';
import emptyImage from '../assets/EmptyImage.png';
import { track } from '../libs/analyticsLib';

const { Meta } = Card;

export default function ProductCard({
  title="",
  description="",
  image_url=emptyImage,
  image_alt = "filler image",
  url="",
  ...props}){

  function handleClick(){
    window.open(url, '_blank');
    track({
      event:"Product Viewed",
      properties:{
        title:title,
        description:description,
        url:url
      }
    });
  }

  return (
      <div onClick={()=>handleClick()}>
        <Card
          hoverable
          cover={
            <img alt={image_alt} src={image_url} height="150" style={{objectFit:"contain"}}/>
          }
        >
          <Meta title={title} description={description} />
        </Card>
        </div>
  );
}
