import React from "react";
import { List, Typography, Empty, Skeleton } from 'antd';
import TaskCard from "./TaskCard";
import { useAppContext } from "../libs/contextLib";

const { Text, Title } = Typography;


export default function TaskList(props) {
  const { isAuthenticated } = useAppContext();
  const tasks = props.tasks;

  let locale = isAuthenticated?{
      emptyText:(
        <Empty
          image=""
          imageStyle={{height:0}}
          description=""
        >
        <div style={{height:400, width:"100%", marginBottom:10}}>
          <img
            src="https://images.unsplash.com/photo-1512552288940-3a300922a275?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
            alt="person relaxing at the beach"
            height="100%"
            width ="100%"
            style={{objectFit:"contain"}}
          />
        </div>
          <Title level={4}>Relax! You're all done for this month!</Title>
        </Empty>
      )
    }:{
      emptyText:(
        <Empty
          image=""
          imageStyle={{height:0}}
          description=""
        >
          <Skeleton />
        </Empty>
      )
  }

  if(props.title && props.title!==""){
    return(
      <List
        header={<Text strong style={{size:"24px", color:"#000"}}>{props.title}</Text>}
        style={{width:'100%', marginBotton:15}}
        itemLayout="vertical"
        dataSource={tasks}
        locale={locale}
        renderItem={task=>(
          <List.Item>
            <TaskCard
              demo={props.demo}
              task={task}
              toggleCompleted={props.toggleCompleted}
            />
          </List.Item>
        )}
      />
    )
  }else{
    return(
      <List
        style={{width:'100%', marginBotton:15}}
        itemLayout="vertical"
        dataSource={tasks}
        locale={locale}
        renderItem={task=>(
          <List.Item>
            <TaskCard
              demo={props.demo}
              task={task}
              toggleCompleted={props.toggleCompleted}
            />
          </List.Item>
        )}
      />
    )
  }
}
