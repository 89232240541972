import React from "react";
import {Card, Typography} from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;
const { Paragraph } = Typography;

export default function ArticleCard({
  image= "https://images.unsplash.com/photo-1523688471150-efdd09f0f312?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80",
  alt="sample image",
  title="title",
  description="",
  url="/home",
  ...props}){

  return (
      <Link to={url}>
        <Card
          hoverable
          cover={
            <img
              alt={alt}
              src={image}
              height="300"
              style={{objectFit:"cover", maxWidth:'100%'}}
              crossOrigin="anonymous"
              referrerPolicy="no-referrer"
            />
          }>
          <Meta title={title} description={<Paragraph ellipsis={{rows:2, expandable:false}}>{description}</Paragraph>} />
        </Card>
      </Link>
  );
}
