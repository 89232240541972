import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Spin, Layout, Typography, Button } from 'antd';
import './App.less';
import Routes from "./Routes";
import NavBar from "./components/NavBar";
import { AppContext, UserContext, HomeContext, DataContext } from "./libs/contextLib";
import { Auth } from 'aws-amplify';
import { onError } from "./libs/errorLib";
import { v4 as uuidv4 } from 'uuid';
import ErrorBoundary from './components/ErrorBoundary';
import { page } from './libs/analyticsLib';
import ScrollToTop from './libs/ScrollToTop';
import { loadDataFromDb, updateHome } from './libs/databaseLib';
import { getAllProducts, getAllTasks, getAllArticles } from './libs/cmsLib';
import { useHistory } from "react-router-dom";

const { Text } = Typography;
const { Footer, Content} = Layout;

function usePageViews() {
  let location = useLocation();
  React.useEffect(()=> {
    page({path:location.pathname});
  }, [location]);
}

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [home, setHome] = useState({});
  const [cmsData, setCMSData] = useState({
    articleLibrary:[],
    taskLibrary:[],
    productLibrary:[]
  });
  const history = useHistory();


  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  async function onLoad() {
    setLoading(true)
    try {
      if(cmsData.articleLibrary.length === 0){
        getCMSData()
      }
      let token = await Auth.currentSession();
      userHasAuthenticated(true);
      let loadDetails = await loadDataFromDb(token);
      setUser(loadDetails.user);
      setHome(loadDetails.home);
      // check for month updates
      if (loadDetails.home.homeId !== ""){
        let monthToTest = new Date().getMonth();
        if(loadDetails.home.homeDetails.activeTasks && loadDetails.home.homeDetails.activeTasks.month !== monthToTest){
          // active tasks exist but are out of date
          //first, remove all completed tasks and mark incomplete tasks as overdue
          var newActiveTaskList = loadDetails.home.homeDetails.activeTasks.tasks
            .filter(task => task.completed === false)
            .map(task=>{
              task.overdue = true;
              return task
            });
          // then add in new needed tasks
          var newMonthTasks = loadDetails.home.homeDetails.tasks
            .filter(task => (task.months[ monthToTest + 1 ]))
            .map(task => {
              task.activeTaskId = uuidv4();
              task.overdue = false;
              task.completed = false;
              return task
            })
          let finalArray = newActiveTaskList.concat(newMonthTasks)
          // save
          let newHome = {
            homeId: loadDetails.user.userDetails.homeId,
            homeDetails: {
              ...loadDetails.home.homeDetails,
              activeTasks:{
                tasks:finalArray,
                month:monthToTest
              }
            }
          }
          setHome(newHome);
          updateHome(newHome);
        }
      }

      setIsAuthenticating(false);
      setLoading(false);
      if(loadDetails.home.homeId === ""){
        history.push("/create-home");
      }

    } catch(e) {
      console.log("Error in APP " + e)
      if (e !== 'No current user') {
        onError(e);
      }
      setIsAuthenticating(false);
      setLoading(false);
    }
  }

  async function getCMSData(){
    let articleLibrary = await getAllArticles();
    let taskLibrary = await getAllTasks();
    let productLibrary = await getAllProducts();
    setCMSData({
      articleLibrary: articleLibrary,
      taskLibrary: taskLibrary,
      productLibrary: productLibrary
    });
  }

  usePageViews()
  if(isAuthenticating || isLoading){
    return(
        <div
          style={{
            marginTop:'30vh',
            textAlign:'center',
            flex: 1,
            justifyContent: 'center',
            alignItems:'center'
          }}
        >
          <Spin size="large" />
        </div>
    )
  }else{
    return (
      !isAuthenticating &&
        <ErrorBoundary>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <UserContext.Provider value={{ user, setUser }}>
            <HomeContext.Provider value={{ home, setHome }}>
              <DataContext.Provider value={{ cmsData, setCMSData}}>
                <ScrollToTop />
                <Layout style={{minHeight:'100vh', backgroundColor:"#f4f4f4"}}>
                  <NavBar />
                  {(isLoading)?(
                    <div
                      style={{
                        marginTop:'50vh',
                        textAlign:'center',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems:'center'
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  ):(
                    <Content style={{marginTop: 64,backgroundColor:'#fefefe', minHeight:'100%', width:'100%'}}>
                      <Routes />
                    </Content>
                  )}
                  <Footer style={{ textAlign: 'center'}}>
                    <Text>HomeStow ©2020</Text>
                    <a href="https://homestow.com/termsofuse" style={{float:'right'}}><Button type="text"><Text style={{fontSize:'12px'}}>Terms of Use</Text></Button></a>
                  </Footer>
                </Layout>
              </DataContext.Provider>
            </HomeContext.Provider>
          </UserContext.Provider>
        </AppContext.Provider>
      </ErrorBoundary>
    );
  }


}

export default App;
