import React from "react";
import { Row, Col, Card, Typography, Space } from 'antd';
import emptyImage from '../assets/EmptyImage.png';
import YelpStars from './YelpStars';
import { track } from '../libs/analyticsLib';

const { Text, Paragraph } = Typography;



export default function ProListingCard({
  listing={},
  ...props}){

  function getCategories(categories){
    let categoriesString = categories.map(category => category.title)
    return categoriesString.join(", ");
  }

  function handleClick(){
    window.open(listing.url, '_blank');
    track({
      event:"Pro Viewed",
      properties:{
        title:listing.name,
        categories:getCategories(listing.categories),
        rating:listing.rating
      }
    });
  };

  return (
      <div onClick={()=>handleClick()}>
        <Card hoverable
          cover={
            <div style={{height:200}}>
            <img
              height="100%"
              width="100%"
              style={{objectFit:"cover"}}
              alt={listing.name+" yelp listing image"}
              src={(listing.image_url==="")?(emptyImage):(listing.image_url)}
            />
            </div>
          }
        >
          <Row gutter={10}  align="middle" justify="space-between">
            <Col>
              <Space direction="vertical">
              <Text strong style={{fontSize:16}}>{listing.name}</Text>
              {<Text>{getCategories(listing.categories)}</Text>}
              <Paragraph>{listing.display_phone}</Paragraph>
              <Space >
                <Text strong style={{marginRight:5, fontSize:14}}>{listing.rating}</Text>
                <div style={{height:20}}>
                  <YelpStars stars={listing.rating} />
                </div>
              </Space>
              <Paragraph>{listing.review_count} reviews on Yelp</Paragraph>
              </Space>
            </Col>
          </Row>
        </Card>
      </div>
  );
}
