import React from "react";
import { Col, Row, Typography, Divider, Skeleton } from 'antd';
import { useDataContext } from "../libs/contextLib";
import TaskList from '../components/TaskList';
import PageSideColumn from '../components/PageSideColumn';

const { Title } = Typography;

export default function TaskLibrary() {
  const { cmsData } = useDataContext();

  return (
    <Row gutter={10} style={{margin:30}} >
      <Col
        xs={{span:24, order:0}}
        sm={{span:24, order:0}}
        md={{span:20, order:0, offset:2}}
        lg={{span:20,order:0, offset:2}}
      >
        <Row>
          <Col span={24}>
            <Title level={4}>Maintenance Task Library</Title>
            <Divider />
          </Col>
        </Row>
        <Row gutter={15} >
          <Col
            xs={{span:24, order:1}}
            sm={{span:24, order:1}}
            md={{span:24, order:1}}
            lg={{span:18, order:0}}
          >
            {(cmsData.taskLibrary === [])?(
              <div
                style={{
                  marginTop:'30vh',
                  textAlign:'center',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems:'center'
                }}
              >
                (<Skeleton active paragraph={{ rows: 6 }} />)
              </div>
            ):(
              <TaskList
                tasks={cmsData.taskLibrary}
                demo={true}
              />
            )}
          </Col>
          <PageSideColumn />
        </Row>
      </Col>
    </Row>
  );
}
