import React, { useState, useEffect } from "react";
import { Col, Row, Typography, Card, Skeleton, Divider } from 'antd';
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { useDataContext } from '../libs/contextLib';
import PageSideColumn from '../components/PageSideColumn';
const { Title, Text } = Typography;

// React Markdown Image Styling:
// https://github.com/rexxars/react-markdown/issues/174


//function Image(props) {
//  return <img {...props} style={{maxWidth: '100%'}} />
//}
//
//<Markdown
//  source="![Espen](https://espen.codes/assets/img/avatar-colored.jpg)"
//  renderers={{image: Image}}
///>

function Image(props) {
  return(
      <div style={{height:350, width:500, maxWidth:'100%', maxHeight:"100%", alignSelf:'center'}}>
        <img {...props} alt="" width='100%' height='100%'  style={{objectFit:"contain", borderRadius:"5px"}}/>
      </div>
  )
}

export default function Article() {
  const { id } = useParams();
  const { cmsData } = useDataContext();
  const [ article, setArticle ] = useState({});

  useEffect(() => {
    let setArt = cmsData.articleLibrary.filter((art) => art.id.toString() === id)[0];
    setArticle(setArt);

    // eslint-disable-next-line
  }, [cmsData]);



  return (
    <Row type="flex" style={{margin:15, marginTop:35}}>
      <Col
        xs={{span:24}}
        sm={{span:22, offset:1}}
        md={{span:20, offset:2}}
        lg={{span:20, offset:2}}
      >
        {article?(
          <>
            <Title level={4}>{article.title}</Title>
            <Text style={{size:'18px', color:'#000'}}>{article.description}</Text>
            <Divider />
          </>
        ):(
          <Skeleton active />
        )}
        <Row gutter={15}>
          <Col
            xs={{span:24, order:1}}
            sm={{span:24, order:1}}
            md={{span:24, order:1}}
            lg={{span:18, order:0}}
          >
            {(article)?(
              <>
                <Row align="middle" justify="center" style={{marginBottom:10}}>
                  <Col>
                    <Card size="small" style={{borderRadius:"5px"}}>
                      <div style={{height:500, width:'100%', maxWidth:'100%', maxHeight:"100%"}}>
                        <img src={article.image} alt={article.image_alt} width='100%' height='100%' style={{objectFit:"cover", borderRadius:"5px"}}/>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <ReactMarkdown
                  source={article.content}
                  renderers={{image: Image}}
                />
              </>
            ):(
              <Skeleton active />
            )}
          </Col>
          <PageSideColumn />
        </Row>
      </Col>
    </Row>
  );
}
