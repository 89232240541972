const dev = {
//  s3: {
//    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
//    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
//  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://omongjdvo2.execute-api.us-east-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_056ViGFX3",
    APP_CLIENT_ID: "f6srbault57f5v9lhthbdjt5i",
    IDENTITY_POOL_ID: "us-east-2:d4d4a60c-3f6f-495b-a41f-d62159e7501a"
  }
};

const prod = {
//  s3: {
//    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
//    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
//  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://fe20i0pf93.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_rzQsG9wtx",
    APP_CLIENT_ID: "c8pdoqbp07hs6kl9vfmo8rk1a",
    IDENTITY_POOL_ID: "us-east-2:1c824a02-67f9-4925-af74-43a177870258"
  }
};


// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
