import React from "react";
import { Col, Row } from 'antd';
import HomeSettings from '../components/HomeSettings';

export default function HomeOnboarding() {
  return (
    <Row type="flex" style={{marginBottom:35}}>
      <Col span={16} offset={4}>
        <HomeSettings />
      </Col>
    </Row>
  );
}
