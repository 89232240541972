import { useContext, createContext } from "react";

export const AppContext = createContext(null);
export const UserContext = createContext(null);
export const HomeContext = createContext(null);
export const DataContext = createContext(null);

export function useUserContext() {
  return useContext(UserContext);
}

export function useAppContext() {
  return useContext(AppContext);
}

export function useHomeContext() {
  return useContext(HomeContext);
}

export function useDataContext() {
  return useContext(DataContext);
}
