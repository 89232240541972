import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Checkbox, Row, Divider, Col, Form, Input, Radio, Typography } from 'antd';
import { useFormFields } from "../libs/hooksLib";
import LoaderButton from '../components/LoaderButton';
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib";
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../libs/contextLib';
import { useHomeContext } from '../libs/contextLib';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { track } from '../libs/analyticsLib';
import { getAllTasks } from '../libs/cmsLib';

const { Title, Text } = Typography;

export default function HomeSettings() {
  const history = useHistory();
  const { user, setUser } = useUserContext();
  const { home, setHome } = useHomeContext();
  const [isLoading, setIsLoading] = useState(false);
  const [taskLibrary, setTaskLibrary] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [fields, handleFieldChange] = useFormFields(home.homeDetails);

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line
  }, []);

  async function getTasks(){
    let results = await getAllTasks();
    setTaskLibrary(results);
  }

  const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

  function handleOptionChange(id){
    var newHomeOptions = fields.homeOptions.map(opt => opt.title === id ? {...opt, checked: !opt.checked} : opt);
    setHasChanged(true);
    handleFieldChange({
      target:{
        id:"homeOptions",
        value:newHomeOptions
      }
    })
  }

  function handleChange(event){
    setHasChanged(true);
    handleFieldChange({
      target:{
        id:event.target.id,
        value:event.target.value
      }
    })
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const firstLoad = (home.homeDetails.zip === "");
      await createHome(fields);
      if(firstLoad){
        history.push("/tasks/active/0000-0000-0000-0000");
      }else{
        history.push("/home");
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function createTaskObject(task){
    let newTaskObject = {
      // id that ties it back to the database of tasks
      dbId: task.id,
      title: task.title,
      // id that ties it to this user's home specifically
      homeTaskId: uuidv4(),
      note:"",
      months:task.months,
      history:[]
    }
    return newTaskObject;
  }

  //for use when there is no existing data and we are setting up the task list for the first time
  function createTaskList(){
    var settingsCustomizer={}
    fields.homeOptions.forEach(option=>{
      settingsCustomizer[option.title] = option.checked;
    })
    var tasks = []
    var add = true;
    taskLibrary.forEach(task => {
      add = true;
      for (var type of task.tags){
        if (!((settingsCustomizer[type.title]) || (typeof settingsCustomizer[type.title] === 'undefined') )){
          add = false;
        }
      }

      if(add || task.title === "Task #1: Complete The HomeStow Welcome Task"){
        tasks.push(createTaskObject(task));
      }
    })
    return tasks
  }

  //for use when there is an existing task list so we don't overwrite the existing data
  function updateTaskList(){
    var settingsCustomizer={}
    fields.homeOptions.forEach(option=>{
      settingsCustomizer[option.title] = option.checked;
    })
    var add = true;
    var newTasks = [];
    // first, create a new list of the current home tasks that we want to keep
    home.homeDetails.tasks.forEach(task => {
      if (task.dbId.toString().includes("custom")){
        newTasks.push({...task});
      }else{
        let tags = taskLibrary.filter(checkTask => checkTask.id === task.dbId)[0].tags
        add = true;
        for (var type of tags){
          if(!((settingsCustomizer[type.title]) || (typeof settingsCustomizer[type.title] === 'undefined') )) {
            add = false;
          }
        }
        if(add){
          newTasks.push({...task});
        }
      }
    })
    // then, look for any missing tasks from the CMS. Do full list but check for the task in newTasks before adding it in.
    taskLibrary.forEach(checkTask => {
      add = true;
      if (newTasks.filter(t => t.dbId.toString() === checkTask.id.toString()).length > 0){
        add = false;
      }
      if(add){
        for (var type of checkTask.tags){
          if (!((settingsCustomizer[type.title]) || (typeof settingsCustomizer[type.title] === 'undefined') )){
            add = false;
          }
        }
      }

      if(add){
        newTasks.push(createTaskObject(checkTask));
      }
    })

    return newTasks;
  }

  function createActiveTaskList(tasks){
    let currentMonth = new Date().getMonth() +1;
    var activeTasks = {};
    activeTasks.month = currentMonth - 1;
    activeTasks.tasks = tasks.filter(task => (task.months[ currentMonth ])).map(task=>{
      // unique id for this active task (to differentiate from overdue tasks of same type)
      return{
        ...task,
        activeTaskId: task.dbId.toString().includes("custom")?"custom-"+uuidv4():uuidv4(),
        completed: false,
        overdue: false
      }
    })
    if(home.homeDetails.zip === ""){
      const welcomeTask = tasks.filter(task=> task.title === "Task #1: Complete The HomeStow Welcome Task")[0];
      activeTasks.tasks.push({
        ...welcomeTask,
        activeTaskId: "0000-0000-0000-0000",
        completed: false,
        overdue: false
      })
    }
    return activeTasks
  }

  async function createHome(fields){
    var homeId=""
    if (home.homeId===""){
      homeId = uuidv4();
    }else{
      homeId = home.homeId
    }
    const firstLoad = (home.homeDetails.zip === "");
    let userTaskLibrary = [];
    let createHomeData = {};
    if(firstLoad){
      userTaskLibrary = createTaskList();
      createHomeData = {
        body:{
          homeDetails:{
            ...fields,
            household:[user.userId],
            tasks:userTaskLibrary,
            activeTasks:createActiveTaskList(userTaskLibrary)
          },
          userId: user.userId,
          homeId:homeId
        }
      }
      track({
        event:"Home Created",
        properties:createHomeData.body
      })
      // if updating and not setting for the first time
    }else{
      userTaskLibrary = updateTaskList();
      createHomeData = {
        body:{
          homeDetails:{
            ...fields,
            household:[user.userId],
            tasks:userTaskLibrary,
            activeTasks:createActiveTaskList(userTaskLibrary)
          },
          userId: user.userId,
          homeId:homeId
        }
      }
      track({
        event:"Home Updated",
        properties:createHomeData.body
      })
    }

    setHome({
      homeId:homeId,
      homeDetails: createHomeData.body.homeDetails,
    })
    updateUser(homeId)
    API.post("dataTable", "/home", createHomeData);
  }

  function updateUser(homeId){
    let apiRequest={
      body: {...user}
    }
    apiRequest.body.userDetails.homeId = homeId;
    setUser({
      userId:user.userId,
      userDetails:apiRequest.body.userDetails
    })
    API.put("dataTable", "/user/"+user.userId, apiRequest);
  }

  function getHomeOptions(){
    return fields.homeOptions.map(option=>{
      return(
        <Row key={option.title}>
          <Checkbox
            id={option.title}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={option.checked}
            style={{margin:5}}
            onChange={()=>handleOptionChange(option.title)}
          />
          <Text style={{margin:5}}>{option.label}</Text>
        </Row>
      )
    })
  }

  return (
    <Row>
      <Col span={24}>
      {(fields.zip==="" || !fields.zip)
      ?
        <>
          <Row type="flex" justify="center" align="middle" style={{marginTop:25}} >
            <Title level={2}>Welcome!</Title>
          </Row>
          <Text style={{color:'#000'}}>We're excited to help you care for your home! To get started, we need some information to create a dashboard that is customized for your home. Please fill out the form below so we can get to work.</Text>
          <Divider />
        </>
      :
        <></>
      }
        <Form
          style={{paddingTop:'50px', textColor:'#000'}}
          onSubmit={handleSubmit}
          layout={'vertical'}
        >
          <Title level={4}>Home Dashboard Customizations</Title>
          <Text style={{color:'#000'}}>We use your location to help you find home pros and to customize your maintenance schedule.</Text>

          <Form.Item
            style={{marginTop:15}}
            label='Address Line 1'
            name='address1'
            initialValue={fields.address1}
          >
            <Input
              type='address1'
              style={{width:'300px'}}
              value={fields.address1}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            style={{marginTop:15}}
            label='Address Line 2'
            name='address2'
            initialValue={fields.address2}
          >
            <Input
              type='address2'
              style={{width:'300px'}}
              value={fields.address2}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            style={{marginTop:15}}
            label='City'
            name='city'
            initialValue={fields.city}
          >
            <Input
              type='city'
              style={{width:'300px'}}
              value={fields.city}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            style={{marginTop:15}}
            label='State'
            name='state'
            initialValue={fields.state}
          >
            <Input
              type='state'
              style={{width:'150px'}}
              value={fields.state}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            style={{marginTop:15}}
            label='Zip Code'
            name='zip'
            initialValue={fields.zip}
            rules={[{required: true, message:"ZIP code is required"}]}
          >
            <Input
              type='zip'
              style={{width:'150px'}}
              value={fields.zip}
              onChange={handleChange}
            />
          </Form.Item>
          <Text strong style={{color:'#000'}}>Please select the best options below:</Text>
          <Form.Item
            label="What type of home do you own?"
            style={{marginTop:15}}
          >
            <Radio.Group value={fields.homeType} size="medium">
              <Radio style={radioStyle} value="single-family" id="homeType" onClick={handleChange}>Single Family Home</Radio>
              <Radio style={radioStyle} value="townhouse" id="homeType" onClick={handleChange} >Townhouse</Radio>
              <Radio style={radioStyle} value="condo" id="homeType" onClick={handleChange} >Condo</Radio>
              <Radio style={radioStyle} value="detached-condo" id="homeType" onClick={handleChange} >Detached Condo</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Does anyone in your home have allergies?">
            <Radio.Group value={fields.allergies} size="medium">
              <Radio style={radioStyle} value="yes" id="allergies" onClick={handleChange}>Yes</Radio>
              <Radio style={radioStyle} value="no" id="allergies" onClick={handleChange} >No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="What kind of water heater do you have?">
            <Radio.Group type="waterHeater" value={fields.waterHeater} size="medium">
              <Radio style={radioStyle} value="conventional" id="waterHeater" onClick={handleChange}>Conventional</Radio>
              <Radio style={radioStyle} value="tankless" id="waterHeater" onClick={handleChange}>Tankless</Radio>
              <Radio style={radioStyle} value="na" id="waterHeater" onClick={handleChange} >N/A</Radio>
            </Radio.Group>
          </Form.Item>
          <Text strong style={{color:'#000'}}>Select all of the items that you are responsible for maintaining:</Text>
          <Form.Item>
            {getHomeOptions()}
          </Form.Item>
          {(home.homeDetails.zip !== "" && hasChanged)?(
            <>
              <Text>Warning: Changing your home settings will reset this month's maintenance progress and erase maintenance history for tasks that are no longer relevant.</Text>
              <br />
            </>
          ):(<></>)}
          <LoaderButton
            disabled={fields.zip==="" || !hasChanged}
            size="large"
            type="primary"
            shape="round"
            style={{margin: 15}}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Save
          </LoaderButton>
        </Form>
      </Col>
    </Row>
  );
}
