import React, { useState } from "react";
import { Form, Input, Col, Row, Typography, Card, Button } from 'antd';
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import "./Login.css";
import LoaderButton from '../components/LoaderButton';
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import { Link } from 'react-router-dom';


const { Title, Paragraph, Text } = Typography;

export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password:""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }


  return (
    <div style={{
      minHeight:'85vh',
      flex:1,
      backgroundImage: `url(https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80)`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}>
    <Row type="flex">
    <Col
      xs={{span:22, offset:1}}
      sm={{span:22, offset:1}}
      md={{span:18, offset:3}}
      lg={{span:16, offset:4}}
    >
    <Card style={{marginTop:30, width:'100%', backgroundColor:'rgba(255, 255, 255, 0.95)', borderRadius:'5px'}}>
      <Row type="flex" justify="center" align="middle" style={{marginTop:30}} >
        <Title level={3}>Welcome Back!</Title>
        </Row>
        <Row  type="flex" justify="center" align="middle">
        <Paragraph>Sign In To Enter</Paragraph>
      </Row>
      <Row type="flex">
      <Col
        xs={{span:22, offset:1}}
        sm={{span:22, offset:1}}
        md={{span:18, offset:3}}
        lg={{span:16, offset:4}}
      >

      <Form
        onSubmit={handleSubmit}
        layout="horizontal"
        style={{padding:'30px'}}
      >
        <Form.Item
          name='email'
          rules={[{required: true, message: "Please enter your email address"}]}
        >
          <Input
            type='email'
            placeholder="email address"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{required: true}]}
        >
          <Input.Password
            type='password'
            placeholder="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Item>
        <Row type="flex" justify="center" align="middle" >
          <Col  span={24} >
            <LoaderButton
              style={{width:'100%'}}
              type="primary"
              isLoading={isLoading}
              disabled={!validateForm()}
              onClick={handleSubmit}
            >
              <Text strong style={{color:validateForm()?"#FFF":"#D9D9D9"}}>Login</Text>
            </LoaderButton>
          </Col>
        </Row>
      </Form>
      <Row type="flex" justify="center" align="middle" >
        <Col  type="flex" justify="center" align="middle" >
        <Paragraph>Don't have an account yet?</Paragraph>
        <Button
          type="primary"
          shape="round"
          style={{width:220, background:"#0A3980", borderColor: "#0A3980"}}
        >
          <Link style={{color:"#FFF"}}to="/signup">Signup</Link>
        </Button>
        </Col>
      </Row>
      </Col>
      </Row>
      </Card>
    </Col>
    </Row>
    </div>
  );
}
