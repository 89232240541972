import React from 'react';
import { Button } from 'antd';
import { useAppContext } from "../libs/contextLib";
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

export default function NavBar() {
  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();


  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/login");
  }

  return (
    <Button danger key="logout" onClick={handleLogout}>
      Logout
    </Button>
  );
}
